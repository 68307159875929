<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="consult-stats">
    <div class="base-card-list" v-if="chartList.length">
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="chartList[0].label"
            :data="chartList[0]"
          ></CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="chartList[1].label"
            :data="chartList[1]"
          ></CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="chartList[2].label"
            :data="chartList[2]"
          ></CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="chartList[3].label"
            :data="chartList[3]"
          ></CommonCard>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardMostContentCategory
            :cardData="chartList[4]"
          ></CardMostContentCategory>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommunicationCard :cardData="chartList[5]"></CommunicationCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommunicationCard :cardData="chartList[6]"></CommunicationCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="chartList[7].label"
            :data="chartList[7]"
          ></CommonCard>
        </el-col>
        
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <ChartServiceStats></ChartServiceStats>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <ChartChatStats></ChartChatStats>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <ChartSolvedQuestionStats></ChartSolvedQuestionStats>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <ChartChatCategoryStats></ChartChatCategoryStats>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

// 通用卡片
import CommonCard from '@/modules/consult/components/consult-service-stats/CommonCard.vue'
import CommunicationCard from '@/modules/consult/components/consult-service-stats/CommunicationCard.vue'
import CardMostContentCategory from '@/modules/consult/components/consult-service-stats/CardMostContentCategory.vue'
import ChartServiceStats from '@/modules/consult/components/consult-service-stats/ChartServiceStats.vue'
import ChartChatStats from '@/modules/consult/components/consult-service-stats/ChartChatStats.vue'
import ChartSolvedQuestionStats from '@/modules/consult/components/consult-service-stats/ChartSolvedQuestionStats.vue'
import ChartChatCategoryStats from '@/modules/consult/components/consult-service-stats/ChartChatCategoryStats.vue'
import { boards } from '@/modules/consult/api/consult-service-stats/index.js'
export default {
  data() {
    return {
      chartList: [],
    }
  },
  methods: {
    getConfig() {
      boards().then((res) => {
        this.chartList = res.data
      })
    },
  },
  created() {
    this.getConfig()
  },
  components: {
    CommonCard,
    CommunicationCard,
    CardMostContentCategory,
    ChartServiceStats,
    ChartChatStats,
    ChartChatCategoryStats,
    ChartSolvedQuestionStats

  },
}
</script>

<style lang="scss" scoped>
.consult-stats {
  .base-card-list {
    .el-col {
      margin-bottom: 10px;
    }
  }
}
</style>
