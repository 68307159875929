
import api from "@/base/utils/request";

// 客服统计
export const boards = data => {
  return api({
    url: "/admin/consult/serviceStats/boards",
    method: "post",
    data,
    notCancel: true
  });
};
// 会话总数统计
export const serviceStats = data => {
  return api({
    url: "/admin/consult/serviceStats/serviceStats",
    method: "post",
    data,
    notCancel: true
  });
};
// 客服咨询数统计
export const chatStats = data => {
  return api({
    url: "/admin/consult/serviceStats/chatStats",
    method: "post",
    data,
    notCancel: true
  });
};
// 解决问题数统计
export const solvedQuestionStats = data => {
  return api({
    url: "/admin/consult/serviceStats/solvedQuestionStats",
    method: "post",
    data,
    notCancel: true
  });
};
// 咨询分类统计
export const chatCategoryStats = data => {
  return api({
    url: "/admin/consult/serviceStats/chatCategoryStats",
    method: "post",
    data,
    notCancel: true
  });
};